@media (max-width: 1023px) {
    @keyframes zooming-condensed-milk {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(11.71875vw, 24.479167vw) scale(.3);
            /*transform: translate(8.203125vw, 17.135416667vw) scale(.3);*/
        }
    }
    @keyframes zooming-palermo {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(-9.895834vw, 25vw) scale(.3);
            /*transform: translate(-6.927083333vw, 17.5vw) scale(.3);*/
        }
    }
    @keyframes zooming-cottage-cheese {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(-25.520834vw, 10.15625vw) scale(.3);
            /*transform: translate(-17.864583333vw, 7.109375vw) scale(.3);*/
        }
    }
    @keyframes zooming-figs {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(-26.04167vw, -10.6770834vw) scale(.3);
            /*transform: translate(-18.229166667vw, -7.473958333vw) scale(.3);*/
        }
    }
    @keyframes zooming-butter {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(-12.5vw, -25.78125vw) scale(.3);
            /*transform: translate(-8.75vw, -18.046875vw) scale(.3);*/
        }
    }
    @keyframes zooming-vanilla {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(8.34vw, -28.125vw) scale(.3);
            /*transform: translate(5.833333333vw, -19.6875vw) scale(.3);*/
        }
    }
    @keyframes zooming-coconut {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(24.7395834vw, -14.84375vw) scale(.3);
            /*transform: translate(17.317708333vw, -10.390625vw) scale(.3);*/
        }
    }
    @keyframes zooming-toffee {
        0% {
            transform: translate(0, 0) scale(1);
        }
        100% {
            transform: translate(26.5625vw, 7.8125vw) scale(.3);
            /*transform: translate(18.59375vw, 5.46875vw) scale(.3);*/
        }
    }

    body{
        .menu{
            display: block;
            height: auto;
            background-color: #EFC7A4;
            padding: 0;
            .block-logo{
                display: inline-block;
                margin: 2.8125vw 0 0 6.25vw;
                vertical-align: top;
                img{
                    width: auto;
                    height: 10.9375vw;
                }
            }
            .navigation{
                width: 9.375vw;
                height: 6.25vw;
                padding: 0;
                margin: 3.125vw 6.25vw 0 0;
                display: inline-block;
                position: absolute;
                right: 0;
                #menuToggle {
                    display: flex !important;
                    flex-direction: column;
                    position: relative;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    -webkit-user-select: none;
                    user-select: none;
                    .isOpen {
                        span {
                            opacity: 1;
                            background: #5D2D16;

                            &:nth-child(1){
                                transform: rotate(45deg);
                                margin: 0;
                            }

                            &:nth-child(2) {
                                opacity: 0;
                                transform: rotate(0deg);
                            }

                            &:nth-child(3) {
                                transform: rotate(-45deg);
                                margin: 4.6875vw 0 0 0;
                            }
                        }
                        & ~ ul {
                            transform-origin: 0% 0%;
                            transform: translate(100vw, 0);
                            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

                        }
                    }
                    span {
                        display: flex;
                        width: 9.375vw;
                        height: 0.9375vw;
                        margin-bottom: 1.5625vw;
                        position: relative;
                        background: #fff;
                        border-radius: 3px;
                        z-index: 1;
                        transform-origin: 5px 0px;
                        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            opacity 0.55s ease;
                        &:first-child{
                            transform-origin: 0% 0%;
                        }
                        &:last-child{
                            margin-bottom: 0;
                            transform-origin: 0% 100%;
                        }

                    }
                }
                #menuToggle.isOpen {
                    position: fixed;
                    right: 0;
                    left: initial;
                    top: 1.875vw;
                }
                #menu-ul {
                    background-color: #FFFFFF;
                    position: fixed;
                    width: 100vw;
                    height: 103vh;
                    padding: 0 15px;
                    top: 0;
                    left: -100vw;
                    padding-top: 0;
                    -webkit-font-smoothing: antialiased;
                    transform: none;
                    li {
                        text-transform: none;
                        text-decoration: none;
                        padding: 0 0 15px 0;
                        transition-delay: 2s;
                        list-style-type: none;
                        a{
                            font-family: @MuseoCyrl;
                            font-size: 4.167vw;
                            font-weight: 500;
                            line-height: 1;
                            color: #5D2D16;
                            text-transform: uppercase;
                            text-align: center;
                        }
                        &:nth-child(1){
                            padding: 90px 0 15px 0;
                        }
                    }
                }
            }
        }
        .block-content{
            .block1{
                background-size: 128.125vw;
                margin: -1.40625vw 0 0 0;
                padding: 3.125vw 0 0 0;
                height: 85.9375vw;
                p{
                    width: 48.4375vw;
                    font-size: 2.8125vw;
                    margin: 0 0 0 0;
                }
            }
            .block2{
                background-size: 42.1875vw;
                margin: -3.125vw 0 0 0;
                height: 93.75vw;
                .block2-content{
                    width: 48.4375vw;
                    h1{
                        font-size: 5.625vw;
                    }
                    p{
                        font-size: 2.8125vw;
                    }
                }
            }
            .block3{
                h1{
                    font-size: 3.75vw;
                }
                p{
                    font-size: 2.8125vw;
                }
                .product{
                    height: 64.0625vw;
                    padding: 0;
                    .product-step{
                        background: url(../images/block3_product_bg.png) no-repeat center center;
                        background-size: contain;
                        width: 18.229167vw;
                        height: 18.229167vw;
                        cursor: pointer;
                    }
                    /*                    .product-step{
                                            background: url(images/block3_product_bg.png) no-repeat center center;
                                            background-size: contain;
                                            width: 12.760416667vw;
                                            height: 12.760416667vw;
                                            cursor: pointer;
                                        }*/
                    .step-active{
                        background: url(../images/block3_product_bg_2.png) no-repeat center center;
                        background-size: contain;
                        p{
                            color: #FFFFFF!important;
                        }
                    }
                    .condensed-milk, .condensed-milk-2{
                        position: relative;
                        top: 0.520834vw;
                        margin-left: 16.145834vw;
                        img{
                            width: 16.53645834vw;
                            height: 15.20834vw;
                            margin: 0.520834vw 0 0 -0.2604167vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -1.8229167vw 0 0 0;
                        }
                    }
                    /*                    .condensed-milk, .condensed-milk-2{
                                            position: relative;
                                            top: 0.390625vw;
                                            margin-left: 12.109375vw;
                                            img{
                                                width: 11.575520833vw;
                                                margin: 0.364583333vw 0 0 -0.182291667vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: -1.276041667vw 0 0 0;
                                            }
                                        }*/
                    .palermo, .palermo-2{
                        margin-top: -18.229167vw;
                        margin-left: 38.020834vw;
                        img{
                            width: 12.109375vw;
                            height: 10.8854167vw;
                            margin: 2.34375vw 0 0 2.0834vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: 0.520834vw 0 0 0;
                        }
                    }
                    /*                    .palermo, .palermo-2{
                                            margin-top: -13.671875vw;
                                            margin-left: 28.515625vw;
                                            img{
                                                width: 8.4765625vw;
                                                margin: 1.640625vw 0 0 1.458333333vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: 0.364583333vw 0 0 0;
                                            }
                                        }*/
                    .cottage-cheese, .cottage-cheese-2{
                        margin-top: -3.3854167vw;
                        margin-left: 53.645834vw;
                        img{
                            width: 12.4479167vw;
                            height: 11.45834vw;
                            margin: 2.604167vw 0 0 2.8645834vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.2604167vw 0 0 0;
                        }
                    }
                    /*                    .cottage-cheese, .cottage-cheese-2{
                                            margin-top: -2.5390625vw;
                                            margin-left: 40.234375vw;
                                            img{
                                                width: 8.713541667vw;
                                                margin: 1.822916667vw 0 0 2.005208333vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: -0.182291667vw 0 0 0;
                                            }
                                        }*/
                    .figs, .figs-2{
                        margin-top: 2.604167vw;
                        margin-left: 54.167vw;
                        img{
                            width: 14.7395834vw;
                            height: 14.86979167vw;
                            margin: 0.520834vw 0 0 2.0834vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -1.5625vw 0 0 0;
                        }
                    }
                    /*                    .figs, .figs-2{
                                            margin-top: 1.953125vw;
                                            margin-left: 40.625vw;
                                            img{
                                                width: 10.317708333vw;
                                                margin: 0.364583333vw 0 0 1.458333333vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: -1.09375vw 0 0 0;
                                            }
                                        }*/
                    .butter, .butter-2{
                        margin-top: -3.125vw;
                        margin-left: 40.625vw;
                        img{
                            width: 20.44270834vw;
                            height: 8.56770834vw;
                            margin: 4.167vw 0 0 -0.78125vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: 0.520834vw 0 0 0;
                        }
                    }
                    /*                    .butter, .butter-2{
                                            margin-top: -2.34375vw;
                                            margin-left: 30.46875vw;
                                            img{
                                                width: 14.309895833vw;
                                                margin: 2.916666667vw 0 0 -0.546875vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: 0.364583333vw 0 0 0;
                                            }
                                        }*/
                    .vanilla, .vanilla-2{
                        margin-top: -15.8854167vw;
                        margin-left: 19.79167vw;
                        img{
                            width: 17.9167vw;
                            height: 10.104167vw;
                            margin: 3.645834vw 0 0 0;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: 0 0 0 0;
                        }
                    }
                    /*                    .vanilla, .vanilla-2{
                                            margin-top: -11.9140625vw;
                                            margin-left: 14.84375vw;
                                            img{
                                                width: 12.541666667vw;
                                                margin: 2.552083333vw 0 0 0;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: 0 0 0 0;
                                            }
                                        }*/
                    .coconut, .coconut-2{
                        margin-top: -31.5104167vw;
                        margin-left: 3.3854167vw;
                        img{
                            width: 15.8854167vw;
                            height: 15.75520834vw;
                            margin: -0.2604167vw 0 0 1.5625vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -1.5625vw 0 0 0;
                        }
                    }
                    /*                    .coconut, .coconut-2{
                                            margin-top: -23.6328125vw;
                                            margin-left: 2.5390625vw;
                                            img{
                                                width: 11.119791667vw;
                                                margin: -0.182291667vw 0 0 1.09375vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: -1.09375vw 0 0 0;
                                            }
                                        }*/
                    .toffee, .toffee-2{
                        margin-top: -40.8854167vw;
                        margin-left: 1.5625vw;
                        img{
                            width: 15.78125vw;
                            height: 14.375vw;
                            margin: 0.520834vw 0 0 0.520834vw;
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -1.04167vw 0 0 0;
                        }
                    }
                    /*                    .toffee, .toffee-2{
                                            margin-top: -27.083333333vw;
                                            margin-left: 1.171875vw;
                                            img{
                                                width: 11.046875vw;
                                                margin: 0.364583333vw 0 0 0.364583333vw;
                                            }
                                            p{
                                                font-family: @MuseoSansCyrl;
                                                font-size: 0.78124vw;
                                                font-weight: 700;
                                                text-align: center;
                                                text-transform: uppercase;
                                                color: #A06440;
                                                margin: -0.729166667vw 0 0 0;
                                            }
                                        }*/
                    .condensed-milk-2, .palermo-2, .cottage-cheese-2, .figs-2, .butter-2, .vanilla-2, .coconut-2, .toffee-2{
                        margin-top: -18.229167vw;
                        display: none;
                        p{
                            display: none;
                        }
                    }
                    /*                    .condensed-milk-2, .palermo-2, .cottage-cheese-2, .figs-2, .butter-2, .vanilla-2, .coconut-2, .toffee-2{
                                            margin-top: -12.760416667vw;
                                            display: none;
                                            p{
                                                display: none;
                                            }
                                        }*/
                    /*                .product-step-center{
                                        width: 31.45834vw; 604px
                                        height: 31.45834vw; 604px
                                        margin-top: -16.67vw; 320px
                                        margin-left: 21.354167vw; 410px
                                        img{
                                            width: 31.45834vw; 604px
                                            height: 31.45834vw; 604px
                                        }
                                    }*/
                    .condensed-milk-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-condensed-milk 3s;
                    }
                    .palermo-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-palermo 3s;
                    }
                    .cottage-cheese-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-cottage-cheese 3s;
                    }
                    .figs-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-figs 3s;
                    }
                    .butter-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-butter 3s;
                    }
                    .vanilla-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-vanilla 3s;
                    }
                    .coconut-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-coconut 3s;
                    }
                    .toffee-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-toffee 3s;
                    }
                    .product-step-center{
                        width: 31.45834vw;
                        height: 31.45834vw;
                        margin-top: -20.625vw;
                        margin-left: 21.354167vw;
                        img{
                            width: 31.45834vw;
                            height: 31.45834vw;
                        }
                    }
                    .product-step-center-animation{
                        display: none;
                        width: 31.45834vw;
                        height: 31.45834vw;
                        margin-top: -20.625vw;
                        margin-left: 21.354167vw;
                        img{
                            width: 31.45834vw;
                            height: 31.45834vw;
                        }
                    }
                    .product-step-center-hide{
                        display: none;
                    }
                    .product-step-center-active{
                        display: block;
                    }
                }
                button{
                    display: block;
                    margin: 0 auto;
                    padding: 0;
                    position: relative;
                    top: -17.5vw;
                    left: initial;
                    p{
                        width: 22.604167vw;
                        height: 5.6770834vw;
                        background-color: #5D2D16;
                        border-radius: 1.04167vw;
                        font-family: @MuseoSansCyrl;
                        font-size: 2.0834vw;
                        font-weight: 700;
                        line-height: 5.6770834vw;
                        text-align: center;
                        color: #FFFFFF;
                        margin: 0 auto;
                    }
                }
            }
            .block4{
                h1{
                    font-size: 3.75vw;
                }
                p{
                    font-size: 2.8125vw;
                }
                .emotions{
                    /*height: 72.65625vw;*/
                    height: 64.0625vw;
                    padding: 0;
                    .emotions-step{
                        background: url(../images/block3_product_bg.png) no-repeat center center;
                        background-size: contain;
                        width: 18.229167vw;
                        height: 18.229167vw;
                        cursor: pointer;
                    }
                    /*                    .emotions-step{
                                            background: url(images/block3_product_bg.png) no-repeat center center;
                                            background-size: contain;
                                            width: 12.760416667vw;
                                            height: 12.760416667vw;
                                            cursor: pointer;
                                        }*/
                    .step-active{
                        background: url(../images/block3_product_bg_2.png) no-repeat center center;
                        background-size: contain;
                        p{
                            color: #FFFFFF!important;
                        }
                    }
                    .inspiration, .inspiration-2{
                        position: relative;
                        top: 0.520834vw;
                        margin-left: 16.145834vw;
                        /*                        position: relative;
                                                top: 0.390625vw;
                                                margin-left: 12.109375vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 11.40625vw;
                            margin: 2.604167vw 0 0 3.645834vw;
                            /*width: 7.473958333vw;*/
                            /*margin: 1.822916667vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .pleasure, .pleasure-2{
                        margin-top: -18.229167vw;
                        margin-left: 38.020834vw;
                        /*                        margin-top: -13.671875vw;
                                                margin-left: 28.515625vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 10.625vw;
                            margin: 3.645834vw 0 0 3.645834vw;
                            /*width: 7.473958333vw;*/
                            /*margin: 2.552083333vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .pride, .pride-2{
                        margin-top: -3.3854167vw;
                        margin-left: 53.645834vw;
                        /*                        margin-top: -2.5390625vw;
                                                margin-left: 40.234375vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 10.625vw;
                            margin: 3.645834vw 0 0 3.645834vw;
                            /*width: 7.473958333vw;*/
                            /*margin: 2.552083333vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .cheerfulness, .cheerfulness-2{
                        margin-top: 2.604167vw;
                        margin-left: 54.167vw;
                        /*                        margin-top: 1.953125vw;
                                                margin-left: 40.625vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 10.625vw;
                            margin: 3.645834vw 0 0 3.645834vw;
                            /*                            width: 7.473958333vw;
                                                        margin: 2.552083333vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .joy, .joy-2{
                        margin-top: -3.125vw;
                        margin-left: 40.625vw;
                        /*                        margin-top: -2.34375vw;
                                                margin-left: 30.46875vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 10.625vw;
                            margin: 3.645834vw 0 0 3.645834vw;
                            /*width: 7.473958333vw;*/
                            /*margin: 2.552083333vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .fun, .fun-2{
                        margin-top: -15.8854167vw;
                        margin-left: 19.79167vw;
                        /*                        margin-top: -11.9140625vw;
                                                margin-left: 14.84375vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 10.625vw;
                            margin: 3.645834vw 0 0 3.645834vw;
                            /*                            width: 7.473958333vw;
                                                        margin: 2.552083333vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .harmony, .harmony-2{
                        margin-top: -31.5104167vw;
                        margin-left: 3.3854167vw;
                        /*                        margin-top: -23.6328125vw;
                                                margin-left: 2.5390625vw;*/
                        img{
                            width: 12.5vw;
                            height: 14.0625vw;
                            margin: 0.2604167vw 0 0 2.604167vw;
                            /*width: 8.75vw;*/
                            /*margin: 0.182291667vw 0 0 1.822916667vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.546875 0 0 0;
                            /*margin: -0.3828125vw 0 0 0;*/
                        }
                    }
                    .astonishment, .astonishment-2{
                        margin-top: -40.8854167vw;
                        margin-left: 1.5625vw;
                        /*                        margin-top: -27.083333333vw;
                                                margin-left: 1.171875vw;*/
                        img{
                            width: 10.6770834vw;
                            height: 10.625vw;
                            margin: 3.645834vw 0 0 3.645834vw;
                            /*width: 7.473958333vw;*/
                            /*margin: 2.552083333vw 0 0 2.552083333vw;*/
                        }
                        p{
                            font-family: @MuseoSansCyrl;
                            font-size: 0.78124vw;
                            font-weight: 700;
                            text-align: center;
                            text-transform: uppercase;
                            color: #A06440;
                            margin: -0.78125vw 0 0 0;
                            /*margin: -0.546875vw 0 0 0;*/
                        }
                    }
                    .inspiration-2, .pleasure-2, .pride-2, .cheerfulness-2, .joy-2, .fun-2, .harmony-2, .astonishment-2{
                        margin-top: -18.229167vw;
                        /*margin-top: -12.760416667vw;*/
                        display: none;
                        p{
                            display: none;
                        }
                    }
                    .inspiration-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-condensed-milk 3s;
                    }
                    .pleasure-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-palermo 3s;
                    }
                    .pride-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-cottage-cheese 3s;
                    }
                    .cheerfulness-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-figs 3s;
                    }
                    .joy-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-butter 3s;
                    }
                    .fun-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-vanilla 3s;
                    }
                    .harmony-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-coconut 3s;
                    }
                    .astonishment-2-active{
                        display: block;
                        background: transparent;
                        background-size: contain;
                        animation: zooming-toffee 3s;
                    }
                    /*                .emotions-step-center{
                                        width: 31.45834vw;
                                        height: 31.45834vw;
                                        margin-top: -16.67vw;
                                        margin-left: 21.354167vw;
                                        img{
                                            width: 31.45834vw;
                                            height: 31.45834vw;
                                        }
                                    }*/
                    .emotions-step-center{
                        width: 31.45834vw;
                        height: 31.45834vw;
                        margin-top: -20.625vw;
                        margin-left: 21.354167vw;
                        img{
                            width: 31.45834vw;
                            height: 31.45834vw;
                        }
                    }
                    .emotions-step-center-animation{
                        display: none;
                        width: 31.45834vw;
                        height: 31.45834vw;
                        margin-top: -20.625vw;
                        margin-left: 21.354167vw;
                        img{
                            width: 31.45834vw;
                            height: 31.45834vw;
                        }
                    }
                    .emotions-step-center-hide{
                        display: none;
                    }
                    .emotions-step-center-active{
                        display: block;
                    }
                }
                button{
                    display: block;
                    margin: 0 auto;
                    padding: 0;
                    position: relative;
                    top: -17.5vw;
                    left: initial;
                    p{
                        width: 22.604167vw;
                        height: 5.6770834vw;
                        background-color: #5D2D16;
                        border-radius: 1.04167vw;
                        font-family: @MuseoSansCyrl;
                        font-size: 2.0834vw;
                        font-weight: 700;
                        line-height: 5.6770834vw;
                        text-align: center;
                        color: #FFFFFF;
                        margin: 0 auto;
                    }
                }
            }
            .block5{
                height: 87.5vw;
                h1{
                    font-size: 5.625vw;
                }
                p{
                    width: auto;
                    font-size: 2.8125vw;
                }
            }
            .block6{
                background-position-y: 76.5625vw;
                h1{
                    font-size: 5.625vw;
                }
                p{
                    font-size: 2.8125vw;
                }
            }
            .block7{
                h1{
                    font-size: 5.625vw;
                }
                p{
                    font-size: 2.8125vw;
                }
                .swiper{
                    .swiper-wrapper{
                        .swiper-slide{
                            .swiper-slide-text {
                                vertical-align: top;
                                h1{
                                    font-size: 2.8125vw;
                                }
                                p{
                                    font-size: 2.8125vw;
                                }
                            }
                        }
                    }
                    .swiper-button-prev{
                        top: 21.875vw;
                    }
                    .swiper-button-next{
                        top: 21.875vw;
                    }
                    .swiper-button-prev.swiper-button-disabled{
                        top: 21.875vw;
                    }
                    .swiper-button-next.swiper-button-disabled{
                        top: 21.875vw;
                    }
                    .swiper-pagination{
                        position: absolute;
                        top: 26.5625vw;
                        .swiper-pagination-bullet-active{
                            vertical-align: initial;
                            margin-bottom: -0.3125vw;
                        }
                        .swiper-pagination-bullet{

                        }
                    }
                }
            }
            .block8{
                h1{
                    font-size: 5.625vw;
                }
            }
        }
    }

    /*body {
        max-width: 100vw;
        width: 100%;
        padding: 0;
        margin: 0;
        .wrapper1440{
            max-width: 100%;
            padding: 0;
            margin: 0;
        }
        .block-input, .block-select{
            display: block;
            width: 59.2vw;
            height: 10.134vw;
            background-color: #F1F3F9;
            padding: 0;
            margin: 0 auto 5.34vw auto;
            border-radius: 5.34vw;
            .MuiInput-root{
                border: none;
                margin: 0;
                padding: 0;
                &:after, &:hover, &:not(.Mui-disabled, .Mui-error), &:before{
                    border: none;
                }
            }
            .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
                border: none;
            }
            .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiInputLabel-shrink, .MuiInputLabel-root.MuiFormLabel-filled{
                background: transparent;
                color: #9D9D9D;
                text-transform: none;
                transform: none;
                top: -6.67vw;
                padding: 0 0 0 5.34vw;
                margin: 0;
            }
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
            label{
                background: transparent;
                font-family: @MontserratMedium;
                font-size: 3.734vw;
                line-height: 10.134vw;
                color: #9D9D9D;
                text-transform: none;
                transform: translate(0, 0);
                padding: 0 0 0 5.34vw;
                margin: 0;
            }
            input{
                width: 48.534vw;
                height: 10.134vw;
                font-family: @MontserratMedium;
                font-size: 3.734vw;
                line-height: 10.134vw;
                color: #9D9D9D;
                text-transform: none;
                padding: 0 0 0 5.34vw;
                margin: 0;
            }
        }
        .block-select{
            .MuiOutlinedInput-root{
                padding: 0!important;
                .MuiOutlinedInput-input{
                    width: 53.867vw;
                    height: 10.134vw;
                    font-family: @MontserratMedium;
                    font-size: 3.734vw;
                    line-height: 10.134vw;
                    color: #9D9D9D;
                    text-transform: none;
                    padding: 0 0 0 5.34vw;
                    margin: 0;
                }
            }
            .MuiAutocomplete-root{
                width: 59.2vw;
            }
            .MuiFormControl-root{
                width: 59.2vw;
            }
            .MuiAutocomplete-option{
                font-family: @MontserratMedium;
                font-size: 3.734vw;
                line-height: 10.134vw;
                color: #9D9D9D;
                text-transform: none;
                padding: 0 0 0 5.34vw;
                margin: 0;
            }
            .MuiOutlinedInput-notchedOutline{
                width: 59.2vw;
                border: none;
                padding: 0;
            }
            .MuiAutocomplete-endAdornment{
                right: 5.34vw!important;
                top: calc(50% - 2vw);
                line-height: 0;
                height: 4vw;
                display: flex;
                align-items: center;
                .MuiAutocomplete-popupIndicator{
                    background: url(/images/select-i-button.png) no-repeat;
                    background-size: contain;
                    width: 8vw;
                    height: 4vw;
                    padding: 0;
                    margin: 0;
                    svg{
                        display: none;
                    }
                }
                .MuiAutocomplete-clearIndicator{
                    svg{
                        width: 8vw;
                        height: 16vw;
                    }
                }
                .MuiAutocomplete-clearIndicator{
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .MuiTableContainer-root{
            background: transparent;
            width: 74.65278vw;
            max-height: none;
            border-radius: 0;
            padding: 0;
            margin: 0;
            box-shadow: none;
            overflow: hidden;
            table{
                thead{
                    tr{
                        th{
                            font-family: @MontserratBold;
                            font-size: 1.67vw;
                            line-height: 1;
                            color: #1A1919;
                            text-transform: none;
                            text-align: center;
                            border: none;
                            padding: 0 0 2.78vw 0;
                            margin: 0;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            font-family: @MontserratMedium;
                            font-size: 1.12vw;
                            line-height: 1;
                            color: #1A1919;
                            text-transform: none;
                            text-align: center;
                            border: none;
                            padding: 0 0 2.78vw 0;
                            margin: 0;
                        }
                        &:last-child{
                            td{
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        #login{
            .MuiDialog-paper{
                max-width: 108.267vw;
                width: 100%;
                max-height: 107.734vw;
                height: 100%;
                background-color: #FFFFFF;
                border-radius: 5.34vw;
            }
            .MuiDialogContent-root{
                width: 74.67vw;
                margin: 12vw auto;
                padding: 0;
            }
            .modal-i-close{
                position: absolute;
                width: 4.267vw;
                height: 4.267vw;
                right: 6.67vw;
                top: 6.67vw;
                cursor: pointer;
            }
            h1{
                width: 100%;
                font-family: @MontserratBold;
                font-size: 6.4vw;
                line-height: 1;
                color: #1A1919;
                text-align: center;
                text-transform: none;
                margin: 0 0 5.34vw 0;
                padding: 0;
            }
            #login-block-password{
                input{
                    width: 42.67vw;
                    text-transform: none;
                    border-radius: 5.34vw 0 0 5.34vw;
                }
            }
            .MuiInputAdornment-root{
                margin: 0;
                .MuiIconButton-root{
                    svg{
                        width: 6.4vw;
                        height: 6.4vw;
                    }
                }
            }
            .login-button{
                display: block;
                background-color: #E20019;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #FFFFFF;
                text-transform: none;
                text-align: center;
                margin: -2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border-radius: 5.34vw;
            }
            .reg-button{
                display: block;
                background-color: transparent;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #E20019;
                text-transform: none;
                text-align: center;
                margin: 2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border: 2px solid #E20019;
                border-radius: 5.34vw;
            }
            a{
                font-family: @MontserratRegular;
                font-size: 4.267vw;
                line-height: 1;
                color: #E30019;
                text-align: center;
                width: 100%;
                text-decoration: underline;
                display: block;
                padding: 0;
                margin: 6.67vw auto 0 auto;
                cursor: pointer;
            }
        }
        #registration{
            .MuiDialog-paper{
                max-width: 108.267vw;
                width: 100%;
                max-height: 193.34vw;
                height: 100%;
                background-color: #FFFFFF;
                border-radius: 5.34vw;
            }
            .MuiDialogContent-root{
                width: 74.67vw;
                margin: 12vw auto;
                padding: 0;
            }
            .modal-i-close{
                position: absolute;
                width: 4.267vw;
                height: 4.267vw;
                right: 6.67vw;
                top: 6.67vw;
                cursor: pointer;
            }
            h1{
                width: 100%;
                font-family: @MontserratBold;
                font-size: 6.4vw;
                line-height: 1;
                color: #1A1919;
                text-align: center;
                text-transform: none;
                margin: 0 0 5.34vw 0;
                padding: 0;
            }
            #registration-block-password, #registration-block-repeat_password{
                input{
                    width: 42.67vw;
                    text-transform: none;
                    border-radius: 5.34vw 0 0 5.34vw;
                }
            }
            .MuiInputAdornment-root{
                margin: 0;
                .MuiIconButton-root{
                    svg{
                        width: 6.4vw;
                        height: 6.4vw;
                    }
                }
            }
            .block-checkbox{
                display: flex;
                width: 59.2vw;
                padding: 0;
                margin: 4vw auto 0 auto;
                .checkbox{
                    display: inline-block;
                    width: 5.34vw;
                    height: 5.34vw;
                    padding: 0;
                    margin: 0;
                    input{
                        width: 5.34vw;
                        height: 5.34vw;
                        position: relative;
                        z-index: 1;
                        opacity: 0;
                        cursor: pointer;
                        &:checked + label:before{
                            content: '';
                            background: url(/images/i-checkbox-checked.png) no-repeat;
                            background-size: contain;
                            display: inline-block;
                            width: 5.34vw;
                            height: 5.34vw;
                        }
                    }
                    label{
                        width: 5.34vw;
                        height: 5.34vw;
                        position: relative;
                        top: -8vw;
                        padding: 0;
                        margin: 0;
                        max-width: 100%;
                        &:before{
                            content: '';
                            background: url(/images/i-checkbox.png) no-repeat;
                            background-size: contain;
                            display: inline-block;
                            width: 5.34vw;
                            height: 5.34vw;
                        }
                    }
                }
                label{
                    display: inline-block;
                    font-family: @MontserratMedium;
                    font-size: 3.2vw;
                    line-height: 1.2;
                    color: #1A1919;
                    padding: 0 0 0 2.67vw;
                    margin: 0;
                    max-width: 100%;
                    vertical-align: top;
                    a{
                        color: #E20019;
                        text-decoration: underline;
                    }
                }
            }
            .block-checkbox.error{
                .checkbox{
                    label{
                        &:before{
                            content: '';
                            background: url(/images/i-checkbox-error.png) no-repeat;
                            background-size: contain;
                            display: inline-block;
                            width: 5.34vw;
                            height: 5.34vw;
                        }
                    }
                }
                label{
                    color: red!important;
                    font-weight: bold;
                    a{
                        color: red!important;
                    }
                }
            }
            .registration-button{
                display: block;
                background-color: #E20019;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #FFFFFF;
                text-transform: none;
                text-align: center;
                margin: 2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border-radius: 5.34vw;
            }
            .login-button{
                display: block;
                background-color: transparent;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #E20019;
                text-transform: none;
                text-align: center;
                margin: 2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border: 2px solid #E20019;
                border-radius: 5.34vw;
            }
        }
        #recovery{
            .MuiDialog-paper{
                max-width: 108.267vw;
                width: 100%;
                max-height: 66.67vw;
                height: 100%;
                background-color: #FFFFFF;
                border-radius: 5.34vw;
            }
            .MuiDialogContent-root{
                width: 74.67vw;
                margin: 12vw auto;
                padding: 0;
            }
            .modal-i-close{
                position: absolute;
                width: 4.267vw;
                height: 4.267vw;
                right: 6.67vw;
                top: 6.67vw;
                cursor: pointer;
            }
            h1{
                width: 100%;
                font-family: @MontserratBold;
                font-size: 6.4vw;
                line-height: 1;
                color: #1A1919;
                text-align: center;
                text-transform: none;
                margin: 0 0 5.34vw 0;
                padding: 0;
            }
            button{
                display: block;
                background-color: #E20019;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #FFFFFF;
                text-transform: none;
                text-align: center;
                margin: -2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border-radius: 5.34vw;
            }
        }
        #message{
            .MuiDialog-paper{
                max-width: 108.267vw;
                width: 100%;
                max-height: 66.67vw;
                height: auto;
                background-color: #FFFFFF;
                border-radius: 5.34vw;
            }
            .MuiDialogContent-root{
                width: 74.67vw;
                margin: 12vw auto;
                padding: 0;
            }
            .modal-i-close{
                position: absolute;
                width: 4.267vw;
                height: 4.267vw;
                right: 6.67vw;
                top: 6.67vw;
                cursor: pointer;
            }
            h1{
                width: 100%;
                font-family: @MontserratBold;
                font-size: 6.4vw;
                line-height: 1;
                color: #1A1919;
                text-align: center;
                text-transform: none;
                margin: 0 0 5.34vw 0;
                padding: 0;
            }
            p{
                font-family: @MontserratMedium;
                font-size: 4.267vw;
                line-height: 1.2;
                color: #000000;
                text-align: center;
            }
            img{
                width: 20vw;
                height: 20vw;
                margin: 0 0 10.67vw 0;
            }
            button{
                display: block;
                background-color: #E20019;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #FFFFFF;
                text-transform: none;
                text-align: center;
                margin: -2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border-radius: 5.34vw;
            }
        }
        #check{
            .MuiDialog-paper{
                max-width: 108.267vw;
                width: 100%;
                max-height: 128vw;
                height: 100%;
                background-color: #FFFFFF;
                border-radius: 5.34vw;
            }
            .MuiDialogContent-root{
                width: 74.67vw;
                margin: 12vw auto;
                padding: 0;
            }
            .modal-i-close{
                position: absolute;
                width: 4.267vw;
                height: 4.267vw;
                right: 6.67vw;
                top: 6.67vw;
                cursor: pointer;
            }
            h1{
                width: 100%;
                font-family: @MontserratBold;
                font-size: 6.4vw;
                line-height: 1;
                color: #1A1919;
                text-align: center;
                text-transform: none;
                margin: 0 0 5.34vw 0;
                padding: 0;
            }
            p{
                font-family: @MontserratMedium;
                font-size: 4.267vw;
                line-height: 1.2;
                color: #000000;
                text-align: center;
            }
            #block-file{
                width: 35.2vw;
                height: 35.2vw;
                border: 0.8vw dashed #000000;
                border-radius: 4vw;
                margin: 0 auto;
                .file{
                    background-color: #E20019;
                    width: 32vw;
                    height: 32vw;
                    border-radius: 2.67vw;
                    margin: 0.8vw;
                    input{
                        position: relative;
                        width: 32vw;
                        height: 32vw;
                        z-index: 1;
                        opacity: 0;
                    }
                    label{
                        width: 32vw;
                        height: 32vw;
                        margin: 0;
                        position: relative;
                        top: -32vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p{
                            font-family: @MontserratExtraLight;
                            font-size: 32vw;
                            line-height: 32vw;
                            color: #FFFFFF;
                            text-align: center;
                            margin: 0;
                        }
                        .fileName{
                            width: 100%;
                            font-family: @MontserratRegular;
                            font-size: 4.267vw;
                            line-height: 1;
                            padding: 0 1.34vw;
                        }
                    }
                }
            }
            #block-file.error{
                border-color: #E20019;
                .file{
                    background-color: #FFFFFF;
                    label{
                        p{
                            color: #E20019;
                        }
                    }
                }
            }
            button{
                display: block;
                background-color: #E20019;
                width: 59.2vw;
                height: 10.134vw;
                font-family: @MontserratExtraBold;
                font-size: 4.267vw;
                line-height: 0.1;
                color: #FFFFFF;
                text-transform: none;
                text-align: center;
                margin: 2.67vw auto 0 auto;
                padding: 0;
                box-shadow: none;
                border-radius: 5.34vw;
            }
        }
        #recipes{
            .MuiDialog-paper{
                max-width: 229.867vw;
                width: 100%;
                max-height: none;
                height: auto;
                background-color: #FFFFFF;
                border-radius: 5.34vw;
            }
            .MuiDialogContent-root{
                width: 100%;
                margin: 16vw 0 5.34vw 0;
                padding: 0;
            }
            .modal-i-close{
                position: absolute;
                width: 4.267vw;
                height: 4.267vw;
                right: 6.67vw;
                top: 6.67vw;
                cursor: pointer;
            }
            .block-recipes-info{
                .title{
                    width: 100%;
                    font-family: @MontserratBold;
                    font-size: 5.34vw;
                    line-height: 1;
                    color: #5E3B24;
                    text-align: center;
                    text-transform: uppercase;
                    margin: 0 0 5.34vw 0;
                    padding: 0;
                }
                .recipes-title{
                    width: 100%;
                    font-family: @MontserratBold;
                    font-size: 5.34vw;
                    line-height: 1.2;
                    color: #E20019;
                    text-align: center;
                    text-transform: none;
                    margin: 0 auto;
                    padding: 0;
                }
                .block-complexity-time{
                    width: 61.074vw;
                    margin: 8vw auto;
                    div{
                        display: inline-block;
                        width: 26.67vw;
                        border: 0.8vw solid #5E3B24;
                        border-radius: 8vw;
                        p{
                            font-family: @MontserratExtraBold;
                            font-size: 3.2vw;
                            line-height: 8.8vw;
                            color: #5E3B24;
                            text-align: center;
                            margin: 0;
                        }
                        &:first-child{
                            margin: 0 7.734vw 0 0;
                        }
                    }
                }
                .recipes-img{
                    display: block;
                    width: auto;
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                }
                h2{
                    width: 100%;
                    font-family: @MontserratBold;
                    font-size: 5.34vw;
                    line-height: 1;
                    color: #FFFFFF;
                    text-align: center;
                    text-transform: none;
                    margin: 0;
                    padding: 8vw 0 0 0;
                }
                .block-ingredients{
                    display: flex;
                    justify-content: center;
                    background-color: #5E3B24;
                    margin: -39.467vw 0 17.34vw 0;
                    padding: 53.34vw 0 17.34vw 0;
                    div{
                        width: 48%;
                        p{
                            font-family: @MontserratMedium;
                            font-size: 2.134vw;
                            line-height: 2;
                            color: #FFFFFF;
                            padding: 2vw 0 0 0;
                            margin: 0;
                        }
                        img{
                            width: 100%;
                        }
                        &:last-child{
                            p{
                                width: 29.34vw;
                                font-family: @MontserratMedium;
                                font-size: 2.134vw;
                                line-height: 1.2;
                                color: #FFFFFF;
                                padding: 0;
                                margin: -8vw 0 0 12vw;
                            }
                        }
                    }
                }
                .block-cooking_method{
                    width: 87%;
                    margin: 4vw auto 0 auto;
                    div{
                        display: flex;
                        align-items: center;
                        margin: 0 0 8vw 0;
                        h1{
                            width: 13.34vw;
                            font-family: @MontserratBold;
                            font-size: 5.34vw;
                            line-height: 1;
                            color: #E20019;
                            text-transform: none;
                            margin: 0;
                            padding: 0;
                        }
                        p{
                            width: 73.6vw;
                            min-height: 5.34vw;
                            font-family: @MontserratMedium;
                            font-size: 2.134vw;
                            line-height: 1.2;
                            color: #000000;
                            display: flex;
                            align-items: center;
                            border-left: 1px solid #E20019;
                            padding: 0 0 0 8vw;
                            margin: 0;
                        }
                    }
                }
            }
        }
        #payment{
            .MuiDialog-paper{
                max-width: 100%;
                width: 100%;
                max-height: 100%;
                height: auto;
                background-color: #FFFFFF;
                padding: 0;
                margin: 0;
            }
            .MuiDialogContent-root{
                padding: 6.25vw 12.5vw;
            }
            .modal-i-close{
                position: absolute;
                width: 7.1875vw;
                height: 7.1875vw;
                right: 6.25vw;
                top: 4.6875vw;
                cursor: pointer;
            }
            h1{
                width: 100%;
                //font-family: @PFDinTextCompProMedium;
                font-size: 10.625vw;
                font-weight: 500;
                line-height: 1;
                color: #000000;
                text-align: center;
                text-transform: uppercase;
                margin: 0;
                padding: 7.8125vw 0;
            }
            .block-input, .block-select{
                display: block;
                width: 75vw;
                height: 18.75vw;
                background-color: #FFFFFF;
                border: 1px solid #cacaca;
                padding: 0;
                margin: 0  0 9.375vw 0;
                .MuiInput-root{
                    border: none;
                    margin: 0;
                    padding: 0;
                    &:after, &:hover, &:not(.Mui-disabled, .Mui-error), &:before{
                        border: none;
                    }
                }
                .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
                    border: none;
                }
                .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.MuiInputLabel-shrink, .MuiInputLabel-root.MuiFormLabel-filled{
                    background: transparent;
                    color: #FFFFFF;
                    text-transform: uppercase;
                    transform: none;
                    top: -12.5vw;
                    padding: 0 0 0 6.25vw;
                    margin: 0;
                    max-width: 100%;
                }
                .MuiOutlinedInput-notchedOutline{
                    border: none;
                }
                .MuiFormControl-root{
                    width: 75vw;
                }
                label{
                    background: #FFFFFF;
                    //font-family: @PFDinTextCompProRegular;
                    font-size: 7.5vw;
                    line-height: 18.125vw;
                    color: #000000!important;
                    text-transform: uppercase;
                    transform: translate(0, 0);
                    padding: 0 0 0 6.25vw;
                    margin: 0;
                    max-width: 85%;
                }
                input{
                    width: 75vw;
                    height: 18.75vw;
                    //font-family: @PFDinTextCompProRegular;
                    font-size: 7.5vw;
                    line-height: 18.75vw;
                    color: #000000;
                    text-transform: uppercase;
                    padding: 0 0 0 6.25vw;
                    margin: 0;
                }
            }
            .block-input.error, .block-select.error{
                label{
                    color: red!important;
                }
            }
            .repeat-sms-link{
                text-decoration: underline;
            }
            p{
                margin: 9.375vw 0;
            }
            .payment-button{
                display: block;
                background: #000000;
                width: 100%;
                height: 18.75vw;
                //font-family: @PFDinTextCompProRegular;
                font-size: 7.5vw;
                line-height: 18.75vw;
                color: #FFFFFF;
                text-transform: none;
                text-align: left;
                margin: 4.6875vw 0 0 0;
                padding: 0 0 0 7.8125vw;
                box-shadow: none;
                border-radius: 0;
                cursor: pointer;
            }
            .payment-button-disabled{
                display: block;
                background: #888888;
                width: 100%;
                height: 18.75vw;
                //font-family: @PFDinTextCompProRegular;
                font-size: 7.5vw;
                line-height: 18.75vw;
                color: #FFFFFF;
                text-transform: none;
                text-align: left;
                margin: 4.6875vw 0 0 0;
                padding: 0 0 0 7.8125vw;
                box-shadow: none;
                border-radius: 0;
                cursor: pointer;
            }
        }
        .MuiTooltip-tooltip{
            background-color: #FFFFFF!important;
            color: #FF0000;
            max-width: 46.875vw;
        }
        .MuiMenu-root{
            .MuiBackdrop-root{
                background-color: transparent;
            }
            .MuiMenu-paper{
                background-color: #E30019;
                color: #FFFFFF;
                .MuiMenuItem-root{
                    font-family: @MontserratSemiBold;
                    font-size: 5vw;
                }
            }
        }
        .menu{
            display: block;
            height: 18.75vw;
            background-color: #E30019;
            padding: 4.6875vw;
            .block-logo{
                display: inline-block;
                margin: 0 21.875vw 0 0;
                vertical-align: top;
                img{
                    width: 33.75vw;
                    height: 9.0625vw;
                }
            }
            .block-menu-language{
                display: inline-block;
                width: 15.625vw;
                height: 6.25vw;
                margin: 1.5625vw 6.25vw 1.5625vw 0;
                vertical-align: top;
                .MuiSelect-select{
                    font-family: @MontserratSemiBold;
                    font-size: 5vw;
                    line-height: 1;
                    color: #FFFFFF;
                    padding: 0;
                    min-height: initial;
                }
                .MuiSelect-icon{
                    background: url(/images/i_menu_language.png) no-repeat center center;
                    background-size: contain;
                    width: 4.0625vw;
                    height: 2.1875vw;
                    right: -6.25vw;
                    top: calc(50% - 1.25vw);
                    path{
                        display: none;
                    }
                }
                .MuiOutlinedInput-notchedOutline{
                    border: none;
                }
            }
            .navigation{
                width: 12.5vw;
                height: 9.375vw;
                padding: 0;
                margin: 0;
                display: inline-block;
                #menuToggle {
                    display: flex !important;
                    flex-direction: column;
                    position: relative;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    -webkit-user-select: none;
                    user-select: none;
                    .isOpen {
                        span {
                            opacity: 1;
                            background: #fff;

                            &:nth-child(1){
                                transform: rotate(45deg);
                                margin: 0;
                            }

                            &:nth-child(2) {
                                opacity: 0;
                                transform: rotate(0deg);
                            }

                            &:nth-child(3) {
                                transform: rotate(-45deg);
                                margin: 6.67vw 0 0 -1px;
                            }
                        }
                        & ~ ul {
                            transform-origin: 0% 0%;
                            transform: translate(100vw, 0);
                            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

                        }
                    }
                    span {
                        display: flex;
                        width: 12.5vw;
                        height: 0.9375vw;
                        margin-bottom: 3.125vw;
                        position: relative;
                        background: #fff;
                        border-radius: 3px;
                        z-index: 1;
                        transform-origin: 5px 0px;
                        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                            opacity 0.55s ease;
                        &:first-child{
                            transform-origin: 0% 0%;
                        }
                        &:last-child{
                            margin-bottom: 0;
                            transform-origin: 0% 100%;
                        }

                    }
                }
                #menuToggle.isOpen {
                    position: fixed;
                    right: 0;
                    left: initial;
                    top: 10px;
                }
                #menu-ul {
                    background-color: #E30019;
                    position: fixed;
                    width: 100vw;
                    height: 103vh;
                    padding: 0 15px;
                    top: 0;
                    left: -100vw;
                    padding-top: 0;
                    -webkit-font-smoothing: antialiased;
                    transform: none;
                    li {
                        //font-family: @PFDinTextCompProRegular;
                        font-size: 7.5vw;
                        //font-family: @IntroHeadRBase;
                        font-size: 7.5vw;
                        font-weight: bold;
                        text-transform: none;
                        text-decoration: none;
                        padding: 0 0 15px 0;
                        transition-delay: 2s;
                        list-style-type: none;
                        a{
                            font-family: @MontserratExtraBold;
                            font-size: 5vw;
                            color: #fff;
                        }
                        &:nth-child(1){
                            padding: 90px 0 15px 0;
                        }
                    }
                }
            }
        }
        .block-content{
            background: transparent;
            background-size: auto;
            padding: 0 0 0 0;
            .block-top{
                padding: 0;
                margin: 0;
                .block-top-content{
                    background: url(/images/mobile-top-bg.png) no-repeat;
                    background-size: contain;
                    background-position-x: center;
                    background-position-y: -18.67vw;
                    width: 100%;
                    height: 243.2vw;
                    padding: 0;
                    margin: 0;
                    h1{
                        font-family: @NoteworthyBold;
                        font-size: 8.534vw;
                        line-height: 1;
                        text-transform: none;
                        height: auto;
                        padding: 18.134vw 0 0 0;
                        margin: 0 0 0 45.34vw;
                    }
                    .top-text{
                        font-family: @NoteworthyBold;
                        font-size: 6.4vw;
                        line-height: 1.4;
                        text-transform: none;
                        width: 80vw;
                        height: auto;
                        padding: 97.34vw 0 0 0;
                        margin: 0 0 0 9.067vw;
                    }
                    .top-text-red{
                        font-family: @NoteworthyBold;
                        font-size: 6.4vw;
                        line-height: 1.4;
                        text-transform: none;
                        width: 80vw;
                        height: auto;
                        padding: 2.67vw 0 0 0;
                        margin: 0 0 0 9.067vw;
                    }
                    .block-top-content-button{
                        background-color: #E30019;
                        width: 90.67vw;
                        height: 16vw;
                        font-family: @MontserratExtraBold;
                        font-size: 5.34vw;
                        line-height: 0.1;
                        color: #FFFFFF;
                        text-transform: none;
                        display: block;
                        padding: 0;
                        margin: 5.867vw auto 0 auto;
                        border-radius: 8vw;
                        top: 0;
                    }
                }
                .block-top-content-kz{
                    background: url(/images/mobile-top-bg-kz.png) no-repeat;
                    background-size: contain;
                    background-position-x: center;
                    background-position-y: -18.67vw;
                    width: 100%;
                    height: 243.2vw;
                    padding: 0;
                    margin: 0;
                    h1{
                        font-family: @NoteworthyBold;
                        font-size: 8.534vw;
                        line-height: 1;
                        text-transform: none;
                        height: auto;
                        padding: 18.134vw 0 0 0;
                        margin: 0 0 0 45.34vw;
                    }
                    .top-text{
                        font-family: @NoteworthyBold;
                        font-size: 6.4vw;
                        line-height: 1.4;
                        text-transform: none;
                        width: 80vw;
                        height: auto;
                        padding: 97.34vw 0 0 0;
                        margin: 0 0 0 9.067vw;
                    }
                    .top-text-red{
                        font-family: @NoteworthyBold;
                        font-size: 6.4vw;
                        line-height: 1.4;
                        text-transform: none;
                        width: 80vw;
                        height: auto;
                        padding: 2.67vw 0 0 0;
                        margin: 0 0 0 9.067vw;
                    }
                    .block-top-content-button{
                        background-color: #E30019;
                        width: 90.67vw;
                        height: 16vw;
                        font-family: @MontserratExtraBold;
                        font-size: 5.34vw;
                        line-height: 0.1;
                        color: #FFFFFF;
                        text-transform: none;
                        display: block;
                        padding: 0;
                        margin: 5.867vw auto 0 auto;
                        border-radius: 8vw;
                        top: 0;
                    }
                }
            }
            .block-rules{
                height: auto;
                padding: 0 0 13.34vw 0;
                margin: -36vw 0 0 0;
                h1{
                    font-family: @MontserratExtraBold;
                    font-size: 7.467vw;
                    line-height: 1;
                    color: #E30019;
                    text-transform: none;
                    text-align: center;
                    padding: 13.34vw 0 0 0;
                    margin: 0;
                }
                .block-rules-content-step-mobile{
                    display: block;
                    margin: 0;
                    .block-rules-content-step-mobile{
                        margin: 6.67vw 0 0 0;
                        img{
                            display: block;
                            width: 14.134vw;
                            height: 14.134vw;
                            margin: 0 auto;
                        }
                        h2{
                            font-family: @MontserratExtraBold;
                            font-size: 4.534vw;
                            line-height: 1.4;
                            color: #000000;
                            text-transform: none;
                            text-align: center;
                            width: 85.34vw;
                            padding: 4vw 0 0 0;
                            margin: 0 auto;
                            span{
                                color: #E30019;
                            }
                        }
                    }
                }
                .rules-images-step{
                    display: none;
                }
                .block-rules-content-step{
                    display: none;
                }
                .block-rules-button{
                    display: none;
                }
                .block-rules-content-link-rules{
                    display: none;
                }
            }
            .block-prizes{
                background: #FFFFFF;
                background-size: contain;
                height: auto;
                padding: 0 0 13.34vw 0;
                margin: 0;
                height: auto;
                .block-prizes-content{
                    display: none;
                }
                .block-prizes-content-swiper-mobile{
                    display: block;
                    position: relative;
                    top: 0;
                    height: auto;
                    h1{
                        font-family: @MontserratExtraBold;
                        font-size: 7.467vw;
                        line-height: 1;
                        color: #E30019;
                        text-align: center;
                        padding: 13.34vw 0 2.67vw 0;
                        margin: 0 0 0 0;
                    }
                    .swiper{
                        .swiper-wrapper{
                            align-items: flex-end;
                            .swiper-slide{
                                .swiper-slide-step1{
                                    img{
                                        display: block;
                                        width: 73.34vw;
                                        height: auto;
                                        margin: 0 auto;
                                    }
                                    h2{
                                        font-family: @MontserratExtraBold;
                                        font-size: 4.534vw;
                                        line-height: 1.4;
                                        color: #000000;
                                        text-transform: none;
                                        text-align: center;
                                        width: 85.34vw;
                                        padding: 6.67vw 0 0 0;
                                        margin: 0 auto;
                                        span{
                                            color: #E30019;
                                        }
                                    }
                                }
                                .swiper-slide-step2{
                                    img{
                                        display: block;
                                        width: 73.34vw;
                                        height: auto;
                                        margin: 0 auto;
                                    }
                                    h2{
                                        font-family: @MontserratExtraBold;
                                        font-size: 4.534vw;
                                        line-height: 1.4;
                                        color: #000000;
                                        text-transform: none;
                                        text-align: center;
                                        width: 85.34vw;
                                        padding: 6.67vw 0 0 0;
                                        margin: 0 auto;
                                        span{
                                            color: #E30019;
                                        }
                                    }
                                }
                            }
                        }
                        .swiper-button-prev{
                            background: url(/images/prize-button-next.png) no-repeat;
                            background-size: contain;
                            width: 34.67vw;
                            height: 34.67vw;
                            top: 10.67vw;
                            left: -5.34vw;
                            transform: rotate(180deg);
                            &:after{
                                content: '';
                            }
                        }
                        .swiper-button-next{
                            background: url(/images/prize-button-next.png) no-repeat;
                            background-size: contain;
                            width: 34.67vw;
                            height: 34.67vw;
                            top: 10.67vw;
                            left: 70.67vw;
                            &:after{
                                content: '';
                            }
                        }
                        .swiper-button-prev.swiper-button-disabled{
                            background: url(/images/prize-button-prev.png) no-repeat;
                            background-size: contain;
                            width: 34.67vw;
                            height: 34.67vw;
                            top: 10.67vw;
                            left: -5.34vw;
                            transform: rotate(0deg);
                            opacity: 1;
                            &:after{
                                content: '';
                            }
                        }
                        .swiper-button-next.swiper-button-disabled{
                            background: url(/images/prize-button-prev.png) no-repeat;
                            background-size: contain;
                            width: 34.67vw;
                            height: 34.67vw;
                            top: 10.67vw;
                            left: 70.67vw;
                            transform: rotate(180deg);
                            opacity: 1;
                            &:after{
                                content: '';
                            }
                        }
                    }
                }
            }
            .block-show{
                height: auto;
                padding: 0 0 0 0;
                margin: 0 0 0 0;
                .block-show-content{
                    background-color: #5E3B24;
                    margin: 0 0 0 0;
                    padding: 0 0 13.34vw 0;
                    .block-show-content-bg{
                        background: transparent;
                        background-size: auto;
                        padding: 0 0 0 0;
                        margin: 0 0 0 0;
                        h1{
                            width: 64vw;
                            font-family: @MontserratExtraBold;
                            font-size: 7.467vw;
                            line-height: 1.2;
                            color: #FFFFFF;
                            text-transform: none;
                            text-align: center;
                            padding: 13.34vw 0 6.67vw 0;
                            margin: 0 auto;
                        }
                        p{
                            width: 88vw;
                            font-family: @MontserratExtraBold;
                            font-size: 4.534vw;
                            line-height: 1.5;
                            color: #FFFFFF;
                            text-align: center;
                            padding: 0 0 6.67vw 0;
                            margin: 0 auto;
                        }
                        .block-show-content-swiper-desktop{
                            display: block;
                            margin: 0 0 0 0;
                            p{
                                font-family: @MontserratExtraBold;
                                font-size: 4.534vw;
                                line-height: 1.5;
                                color: #FFFFFF;
                                text-align: center;
                                padding: 0 0 6.67vw 0;
                                margin: 0 auto;
                            }
                            .swiper{
                                .swiper-wrapper{
                                    align-items: flex-end;
                                    .swiper-slide{
                                        display: flex;
                                        justify-content: center;
                                        &:first-child{
                                            margin: 0 10px 0 0!important;
                                        }
                                        .swiper-slide-step{
                                            div{
                                                width: 90.4vw!important;
                                                height: 51.2vw!important;
                                                margin: 0;
                                                padding: 0;
                                                iframe{
                                                    width: 90.4vw!important;
                                                    height: 51.2vw!important;
                                                    border-radius: 2.0834vw;
                                                    box-shadow: 1px 1px 1.04167vw 0.6945vw rgba(0, 0, 0, 0.2);
                                                }
                                            }
                                            button{
                                                display: block;
                                                width: 73.067vw;
                                                height: 17.6vw;
                                                font-family: @MontserratExtraBold;
                                                font-size: 5.87vw;
                                                line-height: 1;
                                                color: #FFFFFF;
                                                text-transform: none;
                                                border: 0.8vw solid #FFFFFF;
                                                border-radius: 8vw;
                                                margin: 5.34vw auto 8vw auto;
                                            }
                                        }
                                    }
                                    .swiper-slide.swiper-slide-prev{
                                        left: 0;
                                        z-index: -1;
                                        opacity: .4;
                                    }
                                    .swiper-slide.swiper-slide-next{
                                        left: 0;
                                        z-index: -1;
                                        opacity: .4;
                                    }
                                }
                                .swiper-button-prev{
                                    background: url(/images/prize-button-next.png) no-repeat;
                                    background-size: contain;
                                    width: 34.67vw;
                                    height: 34.67vw;
                                    top: 13.34vw;
                                    left: -5.34vw;
                                    transform: rotate(180deg);
                                    &:after{
                                        content: '';
                                    }
                                }
                                .swiper-button-next{
                                    background: url(/images/prize-button-next.png) no-repeat;
                                    background-size: contain;
                                    width: 34.67vw;
                                    height: 34.67vw;
                                    top: 13.34vw;
                                    left: 70.67vw;
                                    &:after{
                                        content: '';
                                    }
                                }
                                .swiper-button-prev.swiper-button-disabled{
                                    background: url(/images/prize-button-prev.png) no-repeat;
                                    background-size: contain;
                                    width: 34.67vw;
                                    height: 34.67vw;
                                    top: 13.34vw;
                                    left: -5.34vw;
                                    transform: rotate(0deg);
                                    opacity: 1;
                                    &:after{
                                        content: '';
                                    }
                                }
                                .swiper-button-next.swiper-button-disabled{
                                    background: url(/images/prize-button-prev.png) no-repeat;
                                    background-size: contain;
                                    width: 34.67vw;
                                    height: 34.67vw;
                                    top: 13.34vw;
                                    left: 70.67vw;
                                    transform: rotate(180deg);
                                    opacity: 1;
                                    &:after{
                                        content: '';
                                    }
                                }
                            }
                        }
                        .block-show-content-swiper-mobile{
                            display: none;
                        }
                    }
                }
            }
            .block-winners{
                background: #E20019;
                height: auto;
                margin: 0 0 0 0;
                .block-winners-content{
                    h1{
                        font-family: @MontserratExtraBold;
                        font-size: 7.467vw;
                        line-height: 1;
                        color: #FFFFFF;
                        text-transform: none;
                        text-align: center;
                        padding: 10.67vw 0;
                        margin: 0;
                    }
                    .block-winners-content-block-filters{
                        display: block;
                        position: relative;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                    .block-winners-tabs{
                        display: block;
                        width: 90.278vw;
                        margin: 0 auto;
                        padding: 0 0 10.67vw 0;
                        .MuiAppBar-root{
                            background-color: transparent;
                            box-shadow: none;
                            .MuiTab-root{
                                background-color: #F2F2F2;
                                width: 33.34%;
                                font-family: @MontserratExtraBold;
                                font-size: 3.467vw;
                                line-height: 1.4;
                                color: #1A1919;
                                text-transform: none;
                                padding: 4vw 6.67vw 8vw 6.67vw;
                                border-radius: 5.34vw 5.34vw 0 0;
                                max-width: none;
                                letter-spacing: initial;
                                margin: 0;
                            }
                            .MuiTab-root.Mui-selected{
                                background-color: #FFFFFF;
                                color: #E30019;
                                border-radius: 5.34vw 5.34vw 0 0;
                            }
                            .MuiTabs-indicator{
                                background-color: transparent;
                            }
                        }
                        .MuiBox-root{
                            background-color: #FFFFFF;
                            padding: 8vw 0 8vw 4vw;
                            margin: -5.34vw 0 0 0;
                            border-radius: 5.34vw;
                            position: relative;
                            z-index: 1;
                            .MuiTableCell-root{
                                &:nth-child(1){
                                    width: 2.43056vw;
                                    text-align: left;
                                    padding: 0 0 2.78vw 0;
                                }
                                &:nth-child(2){
                                    width: 15.625vw;
                                    padding: 0 0 2.78vw 2.78vw;
                                }
                                &:nth-child(3){
                                    width: 20.1389vw;
                                    padding: 0 0 2.78vw 5.34vw;
                                }
                                &:nth-child(4){
                                    width: 16.31945vw;
                                    padding: 0 0 2.78vw 8.34vw;
                                }
                                &:nth-child(5){
                                    width: 20.1389vw;
                                    padding: 0 0 2.78vw 6.945vw;
                                }
                            }
                            .MuiTableCell-head{
                                display: none;
                                text-align: right;
                            }
                            .MuiTableCell-body{
                                text-align: left;
                                font-family: @MontserratMedium;
                                font-size: 3.734vw;
                                line-height: 1.4;
                                vertical-align: top;
                                p{
                                    text-align: left;
                                    font-family: @MontserratMedium;
                                    font-size: 3.734vw;
                                    line-height: 1.4;
                                    margin: 0;
                                }
                            }
                        }
                        .block-winners-content-block-table{
                            width: 85.34vw;
                            margin: 0;
                            p{
                                font-family: @MontserratExtraBold;
                                font-size: 5.34vw;
                                line-height: 1.2;
                                text-align: center;
                                color: #000000;
                            }
                        }
                        .block-winners-content-block-table>div:nth-child(2){
                            width: 85.34vw!important;
                            height: 33.067vw!important;
                            .view{
                                .MuiTableContainer-root{
                                    width: 80vw;
                                }
                            }
                        }
                        .block-winners-content-block-table>div>div:nth-child(3){
                            background: rgba(243, 53, 40, 0.5);
                            width: 0.1389vw!important;
                            right: 0.34723vw!important;
                            top: 0!important;
                            bottom: 1.389vw!important;
                            div{
                                background-color: #F33528!important;
                                width: 0.48612vw!important;
                                height: 5.20834vw!important;
                                left: -0.1389vw!important;
                            }
                        }
                    }
                }
            }
            .block-question{
                width: 90.67vw;
                margin: 0 auto;
                padding: 0 0 13.34vw 0;
                h1{
                    font-family: @MontserratExtraBold;
                    font-size: 7.467vw;
                    line-height: 1;
                    color: #1B1A1A;
                    text-transform: none;
                    text-align: center;
                    margin: 0;
                    padding: 10.67vw 0 5.34vw 0;
                }
                .form-question{
                    display: block;
                    background-color: #FFFFFF;
                    width: 90.67vw;
                    padding: 0 0 10.67vw 0;
                    margin: 5.34vw 0 21.34vw 0;
                    vertical-align: top;
                    border-radius: 5.34vw;
                    box-shadow: 1px 1px 3.4723vw 1px rgba(0, 0, 0, 0.1);
                    h1{
                        font-family: @MontserratBold;
                        font-size: 7.467vw;
                        line-height: 1;
                        text-transform: none;
                        text-align: center;
                        color: #1A1919;
                        padding: 10.67vw 0 5.34vw 0;
                    }
                    .block-textarea{
                        width: 59.2vw;
                        height: 32.534vw;
                        label{
                            color: #9D9D9D!important;
                        }
                        .MuiInputBase-inputMultiline{
                            width: 48.534vw!important;
                            height: 21.867vw!important;
                            resize: none;
                            padding: 5.34vw!important;
                            font-family: @MontserratMedium;
                            font-size: 3.734vw;
                            line-height: 1;
                            color: #9D9D9D;
                            text-transform: none;
                            &:focus{
                                outline: none;
                            }
                        }
                    }
                    .block-textarea.error{
                        label{
                            color: red!important;
                            font-weight: bold;
                        }
                    }
                    .form-question-button{
                        background-color: #E20019;
                        display: block;
                        width: 59.2vw;
                        height: 16vw;
                        font-family: @MontserratExtraBold;
                        font-size: 5.34vw;
                        line-height: 0.1;
                        color: #FFFFFF;
                        text-align: center;
                        padding: 0;
                        margin: 0 auto;
                        border-radius: 8vw;
                    }
                }
                .block-faq{
                    display: block;
                    width: 90.67vw;
                    margin: 0 auto;
                    .block-faq-content{
                        .MuiAccordion-root{
                            background-color: transparent;
                            width: 100%;
                            box-shadow: none;
                            margin: 0;
                            padding: 0;
                            border: none;
                            position: initial;
                            &:first-child{
                                .MuiAccordionSummary-root{
                                    margin: 0;
                                }
                            }
                        }
                        .MuiAccordionSummary-root{
                            background-color: #FFFFFF;
                            border: none;
                            padding: 5.34vw;
                            margin: 5.34vw 0 0 0;
                            border-radius: 5.34vw;
                            .MuiAccordionSummary-content{
                                padding: 0;
                                margin: 0;
                                p{
                                    font-family: @MontserratExtraBold;
                                    font-size: 3.734vw;
                                    line-height: 1;
                                    color: #1A1919;
                                    text-transform: none;
                                }
                            }
                            &:after{
                                content: "+";
                                font-family: @MontserratRegular;
                                font-size: 11.2vw;
                                line-height: 0;
                                margin: 0 0 0 5.34vw;
                            }
                        }
                        .MuiAccordionSummary-root.Mui-expanded{
                            border-radius: 5.34vw 5.34vw 0 0;
                            &:after{
                                content: "-";
                                font-family: @MontserratRegular;
                                font-size: 11.2vw;
                                line-height: 0;
                                margin: 0 0 0 5.34vw;
                            }
                        }
                        .MuiAccordionDetails-root{
                            background-color: #FFFFFF;
                            border: none;
                            padding: 2.67vw 5.34vw 5.34vw 5.34vw;
                            margin: 0;
                            border-radius: 0 0 5.34vw 5.34vw;
                            p{
                                font-family: @MontserratMedium;
                                font-size: 3.734vw;
                                line-height: 1.5;
                                color: #1A1919;
                            }
                        }
                        .MuiCollapse-root{
                            margin: -2.67vw 0 0 0;
                        }
                    }
                }
            }
        }
        .block-lk{
            .block-lk-content{
                background: url(/images/lk-bg-mobile.png) no-repeat bottom left;
                background-size: 100%;
                width: 100%;
                margin: 0 auto;
                padding: 0 0 69.34vw 0;
                .block-h1{
                    background-color: #FFFFFF;
                    height: auto;
                    margin: 0 0 0 0;
                    h1{
                        background: transparent;
                        background-size: auto;
                        height: 40vw;
                        font-family: @MontserratExtraBold;
                        font-size: 7.467vw;
                        line-height: 1;
                        color: #E30019;
                        text-transform: none;
                        text-align: center;
                        margin: 0 0 0 0;
                        padding: 13.34vw 0 0 0;
                    }
                }
                .MuiTableContainer-root{
                    max-height: none;
                }
                .MuiTabs-flexContainer{
                    justify-content: center;
                }
                .MuiAppBar-root{
                    background: transparent;
                    box-shadow: none;
                    margin: -12vw 0 0 0;
                }
                .MuiTab-root{
                    font-family: @MontserratExtraBold;
                    font-size: 4.267vw;
                    line-height: 1;
                    color: #000000;
                    text-transform: none;
                    padding: 0;
                    border: none;
                    margin: 0;
                    &:hover{
                        color: #E30019;
                    }
                    &:last-child{
                        margin: 0;
                    }
                }
                .Mui-selected{
                    border: none;
                    color: #E30019;
                }
                .MuiTabs-indicator{
                    background: transparent;
                }
                .MuiTouchRipple-root{
                    display: none;
                }
                .MuiBox-root{
                    width: 90.278vw;
                    margin: 13.34vw auto 0 auto;
                    padding: 0;
                }
                .lk-block-data-user{
                    background: transparent;
                    background-size: auto;
                    background-position-x: right;
                    background-position-y: bottom;
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    padding: 0 0 0 0;
                    .lk-data-user{
                        background-color: #FFFFFF;
                        display: block;
                        width: 100%;
                        height: 29.34vw;
                        margin: 0 0 0 0;
                        padding: 6.67vw;
                        border-radius: 5.34vw;
                        box-shadow: 1px 1px 3.4723vw 1px rgba(0, 0, 0, 0.1);
                        .MuiTableContainer-root{
                            height: auto;
                        }
                        .MuiTableRow-root{
                            td{
                                font-family: @MontserratBold;
                                font-size: 4.8vw;
                                line-height: 1;
                                color: #000000;
                                text-align: left;
                                padding: 0 0 0 0;
                                &:first-child{
                                    padding: 0 0 0 0;
                                }
                            }
                            &:first-child{
                                td{
                                    padding: 0 0 5.34vw 0;
                                }
                            }
                        }
                    }
                    .lk-data-check{
                        display: block;
                        width: 90.67vw;
                        vertical-align: top;
                        margin: 0;
                        .lk-data-check-block{
                            p{
                                width: 50.134vw;
                                font-family: @MontserratExtraBold;
                                font-size: 4.8vw;
                                line-height: 1.2;
                                color: #000000;
                                text-transform: none;
                                text-align: center;
                                padding: 0;
                                margin: 13.34vw auto 6.67vw auto;
                            }
                        }
                        .lk-data-check-number{
                            background-color: #E20019;
                            display: block;
                            width: 19.467vw;
                            height: 19.467vw;
                            font-family: @MontserratExtraBold;
                            font-size: 8vw;
                            line-height: 1;
                            color: #F1F3F9;
                            text-align: center;
                            margin: 0 auto;
                            padding: 5.867vw 0;
                            border-radius: 10.67vw;
                        }
                        .lk-data-check-button{
                            background-color: #E20019;
                            width: 90.67vw;
                            height: 16vw;
                            font-family: @MontserratExtraBold;
                            font-size: 5.34vw;
                            line-height: 0.1;
                            color: #FFFFFF;
                            text-transform: none;
                            padding: 0;
                            margin: 6.67vw auto 0 auto;
                            border-radius: 8vw;
                        }
                    }
                }
                .lk-block-check{
                    background: transparent;
                    background-size: auto;
                    background-position-x: right;
                    background-position-y: bottom;
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    padding: 0 0 0 0;
                    .MuiTableRow-root{
                        .MuiTableCell-root{
                            font-family: @MontserratBold;
                            font-size: 4.267vw;
                            line-height: 1;
                            color: #E30019;
                        }
                    }
                    .MuiTableBody-root{
                        .MuiTableCell-root{
                            font-family: @MontserratMedium;
                            font-size: 3.734vw;
                            line-height: 1.4;
                            color: #1A1919;
                            vertical-align: top;
                            p{
                                margin: 0;
                            }
                        }
                    }
                    .MuiTableCell-root{
                        &:nth-child(1){
                            width: initial;
                            text-align: left;
                        }
                        &:nth-child(2){
                            width: initial;
                            text-align: right;
                            img{
                                width: 6.67vw;
                                height: 7.467vw;
                                margin: 0 0 0 4vw;
                            }
                        }
                        &:nth-child(3){
                            width: initial;
                            text-align: initial;
                        }
                        &:nth-child(4){
                            width: initial;
                            text-align: initial;
                        }
                    }
                    .lk-check-table{
                        background-color: #FFFFFF;
                        padding: 5.34vw;
                        border-radius: 5.34vw;
                        width: 100%;
                        height: auto;
                        margin: 0;
                        div{
                            &:nth-child(2){
                                height: 48vw!important;
                            }
                        }
                    }
                    .lk-check-table>div>div:nth-child(3){
                        background: rgba(243, 53, 40, 0.5);
                        width: 0.534vw!important;
                        right: 1.34vw!important;
                        top: 0!important;
                        bottom: 5.34vw!important;
                        div{
                            background-color: #F33528!important;
                            width: 1.867vw!important;
                            height: 5.20834vw!important;
                            left: -0.534vw!important;
                        }
                    }
                    .lk-data-check-button{
                        display: block;
                        background-color: #E20019;
                        width: 90.67vw;
                        height: 16vw;
                        font-family: @MontserratExtraBold;
                        font-size: 5.34vw;
                        line-height: 0.1;
                        color: #FFFFFF;
                        text-transform: none;
                        padding: 0;
                        margin: 6.67vw auto 0 auto;
                        border-radius: 8vw;
                    }
                }
                .lk-block-prize{
                    background: transparent;
                    background-size: auto;
                    background-position-x: right;
                    background-position-y: bottom;
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    padding: 0 0 0 0;
                    .MuiTableRow-root{
                        .MuiTableCell-root{
                            font-family: @MontserratBold;
                            font-size: 4.267vw;
                            line-height: 1;
                            color: #E30019;
                        }
                    }
                    .MuiTableBody-root{
                        .MuiTableCell-root{
                            font-family: @MontserratMedium;
                            font-size: 3.734vw;
                            line-height: 1.4;
                            color: #1A1919;
                            vertical-align: top;
                            p{
                                margin: 0;
                            }
                        }
                    }
                    .MuiTableCell-root{
                        &:nth-child(1){
                            width: initial;
                            text-align: left;
                        }
                        &:nth-child(2){
                            width: 29.34vw;
                            text-align: right;
                        }
                        &:nth-child(3){
                            width: initial;
                            text-align: initial;
                        }
                        &:nth-child(4){
                            width: initial;
                            text-align: initial;
                        }
                    }
                    .lk-prize-table{
                        background-color: #FFFFFF;
                        padding: 5.34vw;
                        border-radius: 5.34vw;
                        width: 100%;
                        height: auto;
                        margin: 0;
                        div{
                            &:nth-child(2){
                                height: 64vw!important;
                            }
                        }
                    }
                    .lk-prize-table>div>div:nth-child(3){
                        background: rgba(243, 53, 40, 0.5);
                        width: 0.1389vw!important;
                        right: 0.34723vw!important;
                        top: 0!important;
                        bottom: 1.389vw!important;
                        div{
                            background-color: #F33528!important;
                            width: 0.48612vw!important;
                            height: 5.20834vw!important;
                            left: -0.1389vw!important;
                        }
                    }
                    .lk-data-check-button{
                        display: block;
                        background-color: #E20019;
                        width: 90.67vw;
                        height: 16vw;
                        font-family: @MontserratExtraBold;
                        font-size: 5.34vw;
                        line-height: 0.1;
                        color: #FFFFFF;
                        text-transform: none;
                        padding: 0;
                        margin: 6.67vw auto 0 auto;
                        border-radius: 8vw;
                    }
                }
            }
        }
        .footer{
            background-color: #E30019;
            width: 100%;
            padding: 0 0 16vw 0;
            margin: -21.34vw 0 0 0;
            border-radius: 8vw 8vw 0 0;
            .wrapper1440{
                .footer-content{
                    .block-logo{
                        display: block;
                        width: 40.8vw;
                        height: 26.934vw;
                        vertical-align: top;
                        padding: 10.67vw 0 5.34vw 0;
                        margin: 0 auto;
                        img{
                            display: block;
                            width: 40.8vw;
                            height: 10.934vw;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .navigation{
                        width: 60.834vw;
                        display: block;
                        vertical-align: top;
                        margin: 0 5.625vw;
                        #menu-ul{
                            padding: 0;
                            margin: 0;
                            li{
                                list-style-type: none;
                                display: block;
                                margin: 0 0 5.34vw 0;
                                a{
                                    display: block;
                                    font-family: @MontserratExtraBold;
                                    font-size: 3.734vw;
                                    line-height: 1;
                                    color: #fff;
                                    text-transform: none;
                                    text-decoration: none;
                                    text-align: center;
                                    margin: 0 auto;
                                    &:hover, &:focus {
                                        color: #92D400;
                                    }
                                }
                                &:last-child{
                                    margin: 0;
                                }
                            }
                            .active{
                                color: #92D400;
                            }
                            li.displayNone{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }*/
}
@font-face {
	font-family: 'MuseoSansCyrl';
	src: url('/fonts/MuseoSansCyrl.eot');
	src: local('MuseoSansCyrl'), local('MuseoSansCyrl'),
		url('/fonts/MuseoSansCyrl.eot?#iefix') format('embedded-opentype'),
		url('/fonts/MuseoSansCyrl.woff') format('woff'),
		url('/fonts/MuseoSansCyrl.ttf') format('truetype');
	/*font-weight: bold;*/
	font-style: normal;
}

@font-face {
	font-family: 'MuseoCyrl';
	src: url('/fonts/MuseoCyrl.eot');
	src: local('MuseoSansCyrl'), local('MuseoCyrl'),
		url('/fonts/MuseoCyrl.eot?#iefix') format('embedded-opentype'),
		url('/fonts/MuseoCyrl.woff') format('woff'),
		url('/fonts/MuseoCyrl.ttf') format('truetype');
	/*font-weight: bold;*/
	font-style: normal;
}
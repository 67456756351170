@import 'fonts.less';

@MuseoSansCyrl: 'MuseoSansCyrl', sans-serif;
@MuseoCyrl: 'MuseoCyrl', sans-serif;

html {
    scroll-behavior: smooth;
}

@keyframes zooming-condensed-milk {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((225/100*70)/19.2vw, (470/100*70)/19.2vw) scale(.3);
        /*transform: translate(8.203125vw, 17.135416667vw) scale(.3);*/
    }
}
@keyframes zooming-palermo {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((-190/100*70)/19.2vw, (480/100*70)/19.2vw) scale(.3);
        /*transform: translate(-6.927083333vw, 17.5vw) scale(.3);*/
    }
}
@keyframes zooming-cottage-cheese {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((-490/100*70)/19.2vw, (195/100*70)/19.2vw) scale(.3);
        /*transform: translate(-17.864583333vw, 7.109375vw) scale(.3);*/
    }
}
@keyframes zooming-figs {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((-500/100*70)/19.2vw, (-205/100*70)/19.2vw) scale(.3);
        /*transform: translate(-18.229166667vw, -7.473958333vw) scale(.3);*/
    }
}
@keyframes zooming-butter {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((-240/100*70)/19.2vw, (-495/100*70)/19.2vw) scale(.3);
        /*transform: translate(-8.75vw, -18.046875vw) scale(.3);*/
    }
}
@keyframes zooming-vanilla {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((160/100*70)/19.2vw, (-540/100*70)/19.2vw) scale(.3);
        /*transform: translate(5.833333333vw, -19.6875vw) scale(.3);*/
    }
}
@keyframes zooming-coconut {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((475/100*70)/19.2vw, (-285/100*70)/19.2vw) scale(.3);
        /*transform: translate(17.317708333vw, -10.390625vw) scale(.3);*/
    }
}
@keyframes zooming-toffee {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate((510/100*70)/19.2vw, (150/100*70)/19.2vw) scale(.3);
        /*transform: translate(18.59375vw, 5.46875vw) scale(.3);*/
    }
}
@keyframes shadow {
    0% {
        box-shadow: 0 0 0 0 rgba(93, 45, 22, .9);
    }
    100% {
        box-shadow: 0 0 0 20/19.2vw rgba(93, 45, 22, 0);
    }
}

body{
    background-color: #F6F6F6;

    .swiper-button-prev:focus, .swiper-button-next:focus {
        outline: none;
    }
    .wrapper1420{
        width: 1420/19.2vw;
        margin: 0 auto;
    }
    .block-menu{
        background-color: rgba(255, 255, 255, 0.2);
        width: 100%;
        height: 180/19.2vw;
        margin: 0;
        position: relative;
        z-index: 1;
        .block-logo{
            display: block;
            position: absolute;
            width: 190/19.2vw;
            height: 180/19.2vw;
            left: 865/19.2vw;
            vertical-align: middle;
            img{
                width: auto;
                height: 117/19.2vw;
                padding: 0;
                margin: 32/19.2vw 0;
            }
        }
        .navigation{
            background: url(../images/bg_menu.png) no-repeat;
            background-size: contain;
            background-position-y: 124/19.2vw;
            height: 340/19.2vw;
            width: 100%;
            display: block;
            vertical-align: middle;
            margin: 0;
            padding: 0 100/19.2vw;
            #menu-ul{
                padding: 0;
                margin: 0;
                li{
                    list-style-type: none;
                    display: inline-block;
                    a{
                        font-family: @MuseoSansCyrl;
                        font-size: 28/19.2vw;
                        line-height: 180/19.2vw;
                        color: #D18F67;
                        font-weight: 700;
                        text-transform: none;
                        text-decoration: none;
                        &:hover, &:focus {
                            /*color: #92D400;*/
                        }
                    }
                    &:first-child{
                        /*margin-right: 6.5104167vw;*/
                        /*margin-right: 11.979167vw;*/
                        margin-right: 120/19.2vw;
                    }
                    &:last-child{
                        margin: 0;
                    }
                    &:nth-child(2){
                        /*margin-right: 27.8645834vw;*/
                        /*margin-right: 23.95834vw;*/
                        margin-right: 540/19.2vw;
                    }
                    &:nth-child(3){
                        /*margin-right: 9.895834vw;*/
                        /*margin-right: 11.979167vw;*/
                        margin-right: 185/19.2vw;
                    }
                }
                .active{
                    /*color: #92D400;*/
                }
                li.displayNone{
                    display: none;
                }
            }
        }
    }
    .block-content{
        .button-active{
            border-radius: 1.04167vw;
            animation: shadow 1s ease infinite;
        }
        .block1{
            background: url(../images/bg_block1.png) no-repeat top center;
            background-size: 1920/19.2vw;
            height: 1355/19.2vw;
            margin: -180/19.2vw 0 0 0;
            padding: 370/19.2vw 0 0 0;
            p{
                font-family: @MuseoSansCyrl;
                font-size: 27/19.2vw;
                font-weight: 300;
                text-align: justify;
                line-height: 1.2;
                color: #5D2D16;
                width: 640/19.2vw;
                margin: 0 0 0 125/19.2vw;
            }
            .block-button{
                margin: 80/19.2vw 0 0 345/19.2vw;
                width: 435/19.2vw;
                p{
                    font-family: @MuseoSansCyrl;
                    font-size: 36/19.2vw;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1;
                    color: #5D2D16;
                    width: auto;
                    margin: 0 0 30/19.2vw 0;
                }
                button{
                    display: block;
                    margin: 0 auto;
                    p{
                        width: 435/19.2vw;
                        height: 110/19.2vw;
                        background-color: #5D2D16;
                        border-radius: 20/19.2vw;
                        font-family: @MuseoSansCyrl;
                        font-size: 40/19.2vw;
                        font-weight: 700;
                        line-height: 110/19.2vw;
                        text-align: center;
                        color: #FFFFFF;
                        margin: 0 auto;
                    }
                }
            }
        }
        .block2{
            background: url(../images/bg_block2.png) no-repeat top right;
            background-size: 890/19.2vw;
            height: 1040/19.2vw;
            margin: -125/19.2vw 0 0 0;
            padding: 0 0 0 0;
            position: relative;
            .block2-content{
                width: 695/19.2vw;
                h1{
                    font-family: @MuseoCyrl;
                    font-size: 80/19.2vw;
                    font-weight: 500;
                    line-height: 1.2;
                    color: #A06440;
                    margin: 0 0 75/19.2vw 0;
                    span{
                        color: #E1251B;
                    }
                }
                p{
                    font-family: @MuseoSansCyrl;
                    font-size: 27/19.2vw;
                    font-weight: 300;
                    text-align: justify;
                    line-height: 1.2;
                    color: #5D2D16;
                    margin: 0 0 60/19.2vw 0;
                }
                button{
                    display: block;
                    margin: 0 auto;
                    p{
                        width: 435/19.2vw;
                        height: 110/19.2vw;
                        background-color: #5D2D16;
                        border-radius: 20/19.2vw;
                        font-family: @MuseoSansCyrl;
                        font-size: 40/19.2vw;
                        font-weight: 700;
                        line-height: 110/19.2vw;
                        text-align: center;
                        color: #FFFFFF;
                        margin: 0 auto;
                    }
                }
            }
        }
        .block3{
            background-color: #EFC7A4;
            margin: -80/19.2vw 0 0 0;
            padding: 130/19.2vw 0 0 0;
            p{
                font-family: @MuseoSansCyrl;
                font-size: 30/19.2vw;
                font-weight: 300;
                text-align: center;
                line-height: 1.2;
                color: #5D2D16;
                margin: 0 0 65/19.2vw 0;
            }
            h1{
                font-family: @MuseoSansCyrl;
                font-size: 40/19.2vw;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                line-height: 1.2;
                color: #5D2D16;
                margin: 0 0 85/19.2vw 0;
            }
            .product{
                height: 850/19.2vw;
                padding: 0 0 0 165/19.2vw;
                .product-step{
                    background: url(../images/block3_product_bg.png) no-repeat center center;
                    background-size: contain;
                    width: (350/100*70)/19.2vw;
                    height: (350/100*70)/19.2vw;
                    cursor: pointer;
                }
                .step-active{
                    background: url(../images/block3_product_bg_2.png) no-repeat center center;
                    background-size: contain;
                    p{
                        color: #FFFFFF!important;
                    }
                }
                .condensed-milk, .condensed-milk-2{
                    position: relative;
                    top: (10/100*70)/19.2vw;
                    margin-left: (310/100*75)/19.2vw;

                    img{
                        width: (317.5/100*70)/19.2vw;
                        height: (292/100*70)/19.2vw;
                        margin: (10/100*70)/19.2vw 0 0 (-5/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (-35/100*70)/19.2vw 0 0 0;
                    }
                }
                .palermo, .palermo-2{
                    margin-top: (-350/100*70)/19.2vw;
                    margin-left: (730/100*75)/19.2vw;

                    img{
                        width: (232.5/100*70)/19.2vw;
                        height: (209/100*70)/19.2vw;
                        margin: (45/100*70)/19.2vw 0 0 (40/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (10/100*70)/19.2vw 0 0 0;
                    }
                }
                .cottage-cheese, .cottage-cheese-2{
                    margin-top: (-65/100*70)/19.2vw;
                    margin-left: (1030/100*75)/19.2vw;
                    img{
                        width: (239/100*70)/19.2vw;
                        height: (220/100*70)/19.2vw;
                        margin: (50/100*70)/19.2vw 0 0 (55/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (-5/100*70)/19.2vw 0 0 0;
                    }
                }
                .figs, .figs-2{
                    margin-top: (50/100*70)/19.2vw;
                    margin-left: (1040/100*75)/19.2vw;
                    img{
                        width: (283/100*70)/19.2vw;
                        height: (285.5/100*70)/19.2vw;
                        margin: (10/100*70)/19.2vw 0 0 (40/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (-30/100*70)/19.2vw 0 0 0;
                    }
                }
                .butter, .butter-2{
                    margin-top: (-60/100*70)/19.2vw;
                    margin-left: (780/100*75)/19.2vw;
                    img{
                        width: (392.5/100*70)/19.2vw;
                        height: (164.5/100*70)/19.2vw;
                        margin: (80/100*70)/19.2vw 0 0 (-15/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (10/100*70)/19.2vw 0 0 0;
                    }
                }
                .vanilla, .vanilla-2{
                    margin-top: (-305/100*70)/19.2vw;
                    margin-left: (380/100*75)/19.2vw;
                    img{
                        width: (344/100*70)/19.2vw;
                        height: (194/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 0;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: 0 0 0 0;
                    }
                }
                .coconut, .coconut-2{
                    margin-top: (-605/100*70)/19.2vw;
                    margin-left: (65/100*75)/19.2vw;
                    img{
                        width: (305/100*70)/19.2vw;
                        height: (302.5/100*70)/19.2vw;
                        margin: (-5/100*70)/19.2vw 0 0 (30/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (-30/100*70)/19.2vw 0 0 0;
                    }
                }
                .toffee, .toffee-2{
                    margin-top: (-785/100*70)/19.2vw;
                    margin-left: (30/100*75)/19.2vw;
                    img{
                        width: (303/100*70)/19.2vw;
                        height: (276/100*70)/19.2vw;
                        margin: (10/100*70)/19.2vw 0 0 (10/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 14/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: (-20/100*70)/19.2vw 0 0 0;
                    }
                }
                .condensed-milk-2, .palermo-2, .cottage-cheese-2, .figs-2, .butter-2, .vanilla-2, .coconut-2, .toffee-2{
                    margin-top: (-350/100*70)/19.2vw;
                    display: none;
                    p{
                        display: none;
                    }
                }
                .condensed-milk-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-condensed-milk 3s;
                }
                .palermo-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-palermo 3s;
                }
                .cottage-cheese-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-cottage-cheese 3s;
                }
                .figs-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-figs 3s;
                }
                .butter-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-butter 3s;
                }
                .vanilla-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-vanilla 3s;
                }
                .coconut-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-coconut 3s;
                }
                .toffee-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-toffee 3s;
                }
                .product-step-center{
                    width: (604/100*65)/19.2vw;
                    height: (604/100*65)/19.2vw;
                    margin-top: (-410/100*70)/19.2vw;
                    margin-left: (460/100*70)/19.2vw;
                    img{
                        width: (604/100*65)/19.2vw;
                        height: (604/100*65)/19.2vw;
                    }
                }
                .product-step-center-animation{
                    display: none;
                    width: (604/100*65)/19.2vw;
                    height: (604/100*65)/19.2vw;
                    margin-top: (-410/100*70)/19.2vw;
                    margin-left: (460/100*70)/19.2vw;
                    img{
                        width: (604/100*65)/19.2vw;
                        height: (604/100*65)/19.2vw;
                    }
                }
                .product-step-center-hide{
                    display: none;
                }
                .product-step-center-active{
                    display: block;
                }
            }
            button{
                display: block;
                margin: 0 auto;
                padding: 0;
                position: relative;
                top: -260/19.2vw;
                left: -10/19.2vw;
                p{
                    width: 435/19.2vw;
                    height: 110/19.2vw;
                    background-color: #5D2D16;
                    border-radius: 20/19.2vw;
                    font-family: @MuseoSansCyrl;
                    font-size: 40/19.2vw;
                    font-weight: 700;
                    line-height: 110/19.2vw;
                    text-align: center;
                    color: #FFFFFF;
                    margin: 0 auto;
                }
            }
        }
        .hide-block3{
            display: none;
        }
        .block4{
            display: none;
            background-color: #EFC7A4;
            margin: 0 0 0 0;
            padding: 70/19.2vw 0 0 0;
            p{
                font-family: @MuseoSansCyrl;
                font-size: 30/19.2vw;
                font-weight: 300;
                text-align: center;
                line-height: 1.2;
                color: #5D2D16;
                margin: 0 0 65/19.2vw 0;
            }
            h1{
                font-family: @MuseoSansCyrl;
                font-size: 40/19.2vw;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                line-height: 1.2;
                color: #5D2D16;
                margin: 0 0 85/19.2vw 0;
            }
            .emotions{
                height: 850/19.2vw;
                padding: 0 0 0 165/19.2vw;
                .emotions-step{
                    background: url(../images/block3_product_bg.png) no-repeat center center;
                    background-size: contain;
                    width: (350/100*70)/19.2vw;
                    height: (350/100*70)/19.2vw;
                    cursor: pointer;
                }
                .step-active{
                    background: url(../images/block3_product_bg_2.png) no-repeat center center;
                    background-size: contain;
                    p{
                        color: #FFFFFF!important;
                    }
                }
                .inspiration, .inspiration-2{
                    position: relative;
                    top: (10/100*70)/19.2vw;
                    margin-left: (310/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (219/100*70)/19.2vw;
                        margin: (50/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .pleasure, .pleasure-2{
                    margin-top: (-350/100*70)/19.2vw;
                    margin-left: (730/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (204/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .pride, .pride-2{
                    margin-top: (-65/100*70)/19.2vw;
                    margin-left: (1030/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (204/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .cheerfulness, .cheerfulness-2{
                    margin-top: (50/100*70)/19.2vw;
                    margin-left: (1040/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (204/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .joy, .joy-2{
                    margin-top: (-60/100*70)/19.2vw;
                    margin-left: (780/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (204/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .fun, .fun-2{
                    margin-top: (-305/100*70)/19.2vw;
                    margin-left: (380/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (204/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .harmony, .harmony-2{
                    margin-top: (-605/100*70)/19.2vw;
                    margin-left: (65/100*75)/19.2vw;
                    img{
                        width: (240/100*70)/19.2vw;
                        height: (270/100*70)/19.2vw;
                        margin: 0 0 0 (50/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -10/19.2vw 0 0 0;
                    }
                }
                .astonishment, .astonishment-2{
                    margin-top: (-785/100*70)/19.2vw;
                    margin-left: (30/100*75)/19.2vw;
                    img{
                        width: (205/100*70)/19.2vw;
                        height: (204/100*70)/19.2vw;
                        margin: (70/100*70)/19.2vw 0 0 (70/100*70)/19.2vw;
                    }
                    p{
                        font-family: @MuseoSansCyrl;
                        font-size: 15/19.2vw;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        color: #A06440;
                        margin: -15/19.2vw 0 0 0;
                    }
                }
                .inspiration-2, .pleasure-2, .pride-2, .cheerfulness-2, .joy-2, .fun-2, .harmony-2, .astonishment-2{
                    margin-top: (-350/100*70)/19.2vw;
                    display: none;
                    p{
                        display: none;
                    }
                }
                .inspiration-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-condensed-milk 3s;
                }
                .pleasure-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-palermo 3s;
                }
                .pride-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-cottage-cheese 3s;
                }
                .cheerfulness-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-figs 3s;
                }
                .joy-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-butter 3s;
                }
                .fun-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-vanilla 3s;
                }
                .harmony-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-coconut 3s;
                }
                .astonishment-2-active{
                    display: block;
                    background: transparent;
                    background-size: contain;
                    animation: zooming-toffee 3s;
                }
                .emotions-step-center{
                    width: (604/100*65)/19.2vw;
                    height: (604/100*65)/19.2vw;
                    margin-top: (-410/100*70)/19.2vw;
                    margin-left: (460/100*70)/19.2vw;
                    img{
                        width: (604/100*65)/19.2vw;
                        height: (604/100*65)/19.2vw;
                    }
                }
                .emotions-step-center-animation{
                    display: none;
                    width: (604/100*65)/19.2vw;
                    height: (604/100*65)/19.2vw;
                    margin-top: (-410/100*70)/19.2vw;
                    margin-left: (460/100*70)/19.2vw;
                    img{
                        width: (604/100*65)/19.2vw;
                        height: (604/100*65)/19.2vw;
                    }
                }
                .emotions-step-center-hide{
                    display: none;
                }
                .emotions-step-center-active{
                    display: block;
                }
            }
            button{
                display: block;
                margin: 0 auto;
                padding: 0;
                position: relative;
                top: -260/19.2vw;
                left: -10/19.2vw;
                p{
                    width: 435/19.2vw;
                    height: 110/19.2vw;
                    background-color: #5D2D16;
                    border-radius: 20/19.2vw;
                    font-family: @MuseoSansCyrl;
                    font-size: 40/19.2vw;
                    font-weight: 700;
                    line-height: 110/19.2vw;
                    text-align: center;
                    color: #FFFFFF;
                    margin: 0 auto;
                }
            }
        }
        .active-block4{
            display: block;
        }
        .hide-block4{
            display: none;
        }
        .block5{
            display: none;
            background-size: 1760/19.2vw!important;
            background-repeat: no-repeat!important;
            height: 1080/19.2vw;
            padding: 280/19.2vw 0 0 970/19.2vw;
            img{
                position: absolute;
                left: 0;
                height: 1080/19.2vw;
                margin-top: -280/19.2vw;
            }
            h1{
                font-family: @MuseoCyrl;
                font-size: 92/19.2vw;
                font-weight: 500;
                line-height: 1;
                color: #A06440;
                text-transform: uppercase;
                margin: 0 0 40/19.2vw 0;
            }
            p{
                width: 670/19.2vw;
                font-family: @MuseoSansCyrl;
                font-size: 27/19.2vw;
                font-weight: 300;
                line-height: 1.2;
                color: #AE846B;
                margin: 0 0 55/19.2vw 0;
            }
            a{
                display: block;
                width: 670/19.2vw;
                text-decoration: none;
                position: relative;
                z-index: 1;
                p{
                    width: 435/19.2vw;
                    height: 110/19.2vw;
                    background-color: #5D2D16;
                    border-radius: 20/19.2vw;
                    font-family: @MuseoSansCyrl;
                    font-size: 40/19.2vw;
                    font-weight: 700;
                    line-height: 110/19.2vw;
                    text-align: center;
                    color: #FFFFFF;
                    margin: 0 auto;
                }
            }
        }
        .active-block5{
            display: block;
        }
        .block6{
            background: url(../images/block6_bg.png) no-repeat bottom center;
            background-color: #EFC7A4;
            background-size: 1920/19.2vw;
            background-position-y: 600/19.2vw;
            padding: 0 0 850/19.2vw 0;
            h1{
                font-family: @MuseoCyrl;
                font-size: 80/19.2vw;
                font-weight: 500;
                line-height: 1;
                color: #FFFFFF;
                text-transform: uppercase;
                text-align: center;
                padding: 70/19.2vw 0;
                margin: 0;
            }
            p{
                font-family: @MuseoSansCyrl;
                font-size: 27/19.2vw;
                font-weight: 300;
                line-height: 1.2;
                color: #5D2D16;
                text-align: center;
                margin: 0;
            }
        }
        .block7{
            background-color: #EFC7A4;
            padding: 0 0 170/19.2vw 0;
            h1{
                font-family: @MuseoCyrl;
                font-size: 80/19.2vw;
                font-weight: 500;
                line-height: 1;
                color: #FFFFFF;
                text-transform: uppercase;
                text-align: center;
                padding: 70/19.2vw 0;
                margin: 0;
            }
            .swiper-button-prev:focus, .swiper-button-next:focus {
                outline: none;
            }
            .swiper{
                .swiper-wrapper{
                    .swiper-slide{
                        .swiper-slide-img{
                            display: inline-block;
                            background-color: rgba(255, 230, 208, 0.3);
                            width: 600/19.2vw;
                            padding: 15/19.2vw 30/19.2vw 40/19.2vw 30/19.2vw;
                            border: 1px solid #FFFFFF;
                            border-radius: 55/19.2vw;
                            img{
                                width: 575/19.2vw;
                                height: 595/19.2vw;
                            }
                        }
                        .swiper-slide-text{
                            display: inline-block;
                            width: 745/19.2vw;
                            vertical-align: middle;
                            margin: 0 0 0 70/19.2vw;
                            h1{
                                font-family: @MuseoSansCyrl;
                                font-size: 27/19.2vw;
                                font-weight: bold;
                                line-height: 1.2;
                                color: #5D2D16;
                                text-align: left;
                                text-transform: none;
                                margin: 0;
                                padding: 0;
                            }
                            p{
                                width: 685/19.2vw;
                                font-family: @MuseoSansCyrl;
                                font-size: 27/19.2vw;
                                font-weight: 300;
                                line-height: 1.2;
                                color: #5D2D16;
                                margin: 0;
                            }
                        }
                    }
                }
                .swiper-button-prev{
                    background: url(../images/block7_button_next.png) no-repeat;
                    background-size: contain;
                    width: 45/19.2vw;
                    height: 50/19.2vw;
                    top: 310/19.2vw;
                    left: 25/19.2vw;
                    transform: rotate(180deg);
                    &:after{
                        content: '';
                    }
                }
                .swiper-button-next{
                    background: url(../images/block7_button_next.png) no-repeat;
                    background-size: contain;
                    width: 45/19.2vw;
                    height: 50/19.2vw;
                    top: 310/19.2vw;
                    left: 530/19.2vw;
                    &:after{
                        content: '';
                    }
                }
                .swiper-button-prev.swiper-button-disabled{
                    background: url(../images/block7_button_prev.png) no-repeat;
                    background-size: contain;
                    width: 45/19.2vw;
                    height: 50/19.2vw;
                    top: 310/19.2vw;
                    left: 25/19.2vw;
                    transform: rotate(0deg);
                    opacity: 1;
                    &:after{
                        content: '';
                    }
                }
                .swiper-button-next.swiper-button-disabled{
                    background: url(../images/block7_button_prev.png) no-repeat;
                    background-size: contain;
                    width: 45/19.2vw;
                    height: 50/19.2vw;
                    top: 310/19.2vw;
                    left: 530/19.2vw;
                    transform: rotate(180deg);
                    opacity: 1;
                    &:after{
                        content: '';
                    }
                }
                .swiper-pagination{
                    width: 600/19.2vw;
                    position: relative;
                    top: -65/19.2vw;
                    .swiper-pagination-bullet{
                        width: 22/19.2vw;
                        height: 22/19.2vw;
                        background-color: #FFE6D0;
                        opacity: 1;
                    }
                    .swiper-pagination-bullet-active{
                        width: 34/19.2vw;
                        height: 34/19.2vw;
                        background-color: #A06440;
                        vertical-align: bottom;
                    }
                }
            }
        }
        .block8{
            padding: 0 0 110/19.2vw 0;
            h1{
                font-family: @MuseoCyrl;
                font-size: 80/19.2vw;
                font-weight: 500;
                line-height: 1;
                color: #A06440;
                text-transform: uppercase;
                text-align: center;
                padding: 70/19.2vw 0 250/19.2vw 0;
                margin: 0;
            }
            .col-4{
                padding: 0 40/19.2vw;
                display: flex;
                align-items: center;
                margin: 0 0 80/19.2vw 0;
                img{
                    width: 100%;
                }
            }
        }
    }
    .block-notFound{
        h1{
            width: 100%;
            padding: 50px 0 50px 0;
            font-size: 27px;
            font-weight: normal;
            line-height: 1;
            color: #000;
            text-align: center;
            text-transform: uppercase;
            margin: 0;
        }
    }
    .footer{

    }
}
@import 's1280.less';
@import 's640.less';